import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: "20px",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "12px",
      marginBottom: "12px",
      flexDirection: "inherit",
    },
  },
  navajo: {
    margin: "0 auto",
  },
  desktop: {
    background: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      width: "320px",
      maxHeight: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "728px",
      maxHeight: "90px",
    },
  },
  hereText: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    textTransform: "none",
  },
}));

const NavajoZillaDesktop = props => {
  const classes = useStyles(props);
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  const handleAddBlockDetected = value => {
    setAdBlockDetected(value);
  };

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://wheretomine.io/advertise.js"; // changed name of bait; seems ads.js was considered safe.
    script.onload = () => handleAddBlockDetected(false);
    script.onerror = err => {
      console.log(
        "It seems like you're using AdBlock. That sucks for us, we won't make any money from ads. Well, at least you're reading this now. Here's a riddle / joke: What's green and climing a wall? An ambitios cucumber. Have a nice day!"
      );
      handleAddBlockDetected(true);
    };
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const coinzillaScript = document.createElement("script");
    coinzillaScript.src = "https://coinzillatag.com/lib/display.js";
    coinzillaScript.async = true;
    document.body.appendChild(coinzillaScript);
    // coinzillaScript.onload = () => {
    //   console.log("Successfully loaded coinzilla desktop script");
    // };

    // coinzillaScript.onerror = err => {
    //   console.log(err);
    // };

    // Desktop
    const coinzillaDisplay = window.coinzilla_display || [];
    window.coinzilla_display = coinzillaDisplay;
    const desktop_preferences = {};
    desktop_preferences.zone = "605525b8033a0aa911";
    desktop_preferences.width = "728";
    desktop_preferences.height = "90";
    coinzillaDisplay.push(desktop_preferences);

    return () => {
      document.body.removeChild(coinzillaScript);
    };
  }, []);

  if (adBlockDetected) {
    return (
      <div>
        <div className={clsx(classes.navajo, classes.desktop)}>
          <div className="coinzilla" data-zone="C-605525b8033a0aa911" style={{ width: "728px", height: "90px" }}></div>
          <Typography
            className={classes.hereText}
            variant="subtitle1"
            style={{ fontWeight: "400", fontSize: "0.8rem", textAlign: "right" }}
          >
            <div className={classes.hereText}>Please disable AdBlock (*_*)/</div>
          </Typography>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className={clsx(classes.navajo, classes.desktop)}>
          <div className="coinzilla" data-zone="C-605525b8033a0aa911" style={{ width: "728px", height: "90px" }}></div>
          <Typography
            className={classes.hereText}
            variant="subtitle1"
            style={{ fontWeight: "500", fontSize: "0.9rem", textAlign: "right" }}
          >
            <Link className={classes.hereText} to="/advertise">
              Advertise Here
            </Link>
          </Typography>
        </div>
      </div>
    );
  }
};

export default NavajoZillaDesktop;
