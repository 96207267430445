import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { getFallbackNavajo } from "./Navajo";
import OutboundLink from "../Constant/OutboundLink";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: props => {
        return props.children ? "20px" : "0px";
      },
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "12px",
      marginBottom: "12px",
      flexDirection: "inherit",
    },
  },
  link: {
    display: "flex",
    flex: "1",

    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: "1rem",
    WebkitAnimation: "fadein 0.66s",
    WebkitAnimationTimingFunction: "ease-in-out",
    MozAnimation: "fadein 0.66s",
    MozAnimationTimingFunction: "ease-in-out",
    OAnimation: "fadein 0.66s",
    OAnimationTimingFunction: "ease-in-out",
    animation: "fadein 0.66s",
    animationTimingFunction: "ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: 10,
      flexDirection: "column",
      alignItems: "start",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      marginRight: 10,
      marginBottom: 0,
    },
  },
  navajo: {
    flexGrow: 1,
    fontWeight: "600",
    fontSize: "1rem",
  },
  navajoLogoWrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginRight: 8,
      marginBottom: 8,
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginRight: 8,
      marginBottom: 0,
    },
  },
  navajoLogo: {
    padding: "1px 4px",
    backgroundColor: "orange",
    borderRadius: 4,
  },
}));

const NavajoAboveTable = props => {
  const classes = useStyles(props);
  const [navajo, setNavajo] = useState({
    title: "",
    img: "",
    thumbnail: "",
    description: "",
    description_short: "",
    cta_button: "",
    website: "",
    name: "",
    url: "",
    impressionUrl: "",
  });

  useEffect(() => {
    const url = "https://request-global.czilladx.com/serve/native.php?z=93985b8033a0adc71";
    axios
      .get(url)
      .then(response => {
        const navajo = response.data;
        if (typeof navajo.ad.title === "undefined") {
          const customNavajo = getFallbackNavajo();
          setNavajo(customNavajo);
        } else {
          axios.get(navajo.ad.impressionUrl);
          setNavajo(navajo.ad);
        }
      })
      .catch(err => {
        const customNavajo = getFallbackNavajo();
        setNavajo(customNavajo);
      });
  }, []);

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        {navajo.title === "" ? (
          <div className={classes.link}></div>
        ) : (
          <OutboundLink
            className={classes.link}
            href={navajo.url}
            category="Navajo Above Table"
            action="Click"
            label={`${navajo.title}`}
            value={101}
            style={{ textDecoration: "none", marginRight: 10 }}
          >
            <span className={classes.navajoLogoWrapper}>
              <div className={classes.navajoLogo}>
                <Typography variant="subtitle1" style={{ fontWeight: 700, fontSize: "0.7rem", color: "white" }}>
                  AD
                </Typography>
              </div>
            </span>
            <span style={{ display: "flex" }}>
              <Typography variant="h6" className={classes.navajo}>
                {navajo.name} - {navajo.description_short}
              </Typography>
            </span>
          </OutboundLink>
        )}
        {props.children}
      </div>
    </Container>
  );
};

export default NavajoAboveTable;
