import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: 1,
    margin: "0px",
    /* eslint-disable no-dupe-keys */
    background: `-webkit-gradient(linear, 0 0, 100% 0, from(rgba(59, 51, 185, 0)), color-stop(0.5, ${theme.palette.border.gradient.main}), to(rgba(59, 51, 185, 0)))`,
    background: `-webkit-linear-gradient(left, rgba(59, 51, 185, 0), ${theme.palette.border.gradient.main}, rgba(59, 51, 185, 0))`,
    background: `-moz-linear-gradient(left, rgba(59, 51, 185, 0), ${theme.palette.border.gradient.main}, rgba(59, 51, 185, 0))`,
    background: `-o-linear-gradient(left, rgba(59, 51, 185, 0), ${theme.palette.border.gradient.main}, rgba(59, 51, 185, 0))`,
    background: `linear-gradient(left, rgba(59, 51, 185, 0), ${theme.palette.border.gradient.main}, rgba(59, 51, 185, 0))`,
    border: 0,
    display: "block",
  },
}));

const GradientLine = () => {
  const classes = useStyles();
  return <div className={classes.root}></div>;
};

export default GradientLine;
