export const getFallbackNavajo = () => {
  const navajos = [
    {
      name: "CoMining",
      title: "CoMining",
      description_short: "Instant payouts, 1% Fee, servers all around the world.",
      url: "https://comining.io/?ref=AFx9pURxsWfsqiVXF5s5f8g",
    },
    {
      name: "HashCity",
      title: "HashCity",
      description_short: "Best Mining Pool for Ethereum, Monero, Callisto, Moac.",
      url: "https://hashcity.org/?ref=wheretomineio",
    },
    {
      name: "ViaBTC",
      title: "ViaBTC",
      description_short: "Join the world's TOP mining pool.",
      url: "https://pool.viabtc.com/signup/?refer=220676",
    },
    {
      name: "H2C",
      title: "Hash-To-Coins",
      description_short: "Join an exclusive Scrypt mining pool",
      url: "https://hash-to-coins.com/index.php?page=register&rid=59616",
    },
    {
      name: "Binance",
      title: "Binance",
      description_short: "Join The World's Leading Cryptocurrency Exchange",
      url: "https://www.binance.com/en/register?ref=35197232",
    },
    {
      name: "Changelly",
      title: "Changelly",
      description_short: "Fast & secure way to exchange 150+ cryptocurrencies",
      url: "https://changelly.com/?ref_id=ad68503e5aad",
    },
    {
      name: "EMCD.io",
      title: "EMCD.io",
      description_short: "The best pool for sha256 and scrypt.",
      url: "https://emcd.io/?r=11515 ",
    },
    {
      name: "OKEx",
      title: "OKEx",
      description_short: "The World's Leading One-Stop Cryptocurrency Exchange",
      url: "https://www.okex.com/join/1910237",
    },
  ];

  return navajos[Math.floor(Math.random() * navajos.length)];
};
