import React from "react";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const OutboundLink = props => {
  const { href, category, action, label, value, style, className } = props;

  const handleClick = e => {
    e.preventDefault();

    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: category,
      // string - required - Type of interaction (e.g. 'play')
      action: action,
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: label,
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: value,
    });

    const aLink = document.createElement("a");
    aLink.href = href;
    aLink.target = "_blank";
    aLink.rel = "noopener noreferrer";
    aLink.click();
  };

  return (
    <a className={className} href={href} target="_blank" rel="noopener noreferrer" style={style} onClick={handleClick}>
      {props.children}
    </a>
  );
};

export default OutboundLink;
