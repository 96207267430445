import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "gatsby-theme-material-ui";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    alignItems: "center",
    display: "flex",
    borderTop: `1px solid ${theme.palette.border.gradient.main}`,
    borderBottom: `1px solid ${theme.palette.border.gradient.main}`,
    [theme.breakpoints.down("md")]: {
      height: 48,
    },
    [theme.breakpoints.up("md")]: {
      height: 64,
    },
  },
  breadcrum: {
    fontWeight: "500",
    fontSize: "0.9rem",
  },
}));

const BreadcrumbsControls = props => {
  const { breadcrums, currentPage } = props;
  const classes = useStyles(props);

  return (
    <Container className={classes.root} maxWidth="xl">
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
        {breadcrums.map((breadcrum, index) => {
          return (
            <Link key={index} color="inherit" to={breadcrum.to}>
              {breadcrum.page}
            </Link>
          );
        })}
        <Typography color="textPrimary" className={classes.breadcrum}>
          {currentPage}
        </Typography>
      </Breadcrumbs>
    </Container>
  );
};

export default BreadcrumbsControls;
